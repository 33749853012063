import Vue from 'vue';
import VueRouter from 'vue-router';
import layout from '@/layout/layout.vue'

Vue.use(VueRouter);

const routes = [{
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue')
}, {
    path: '/',
    component: layout,
    children: [{
        path: '/',
        redirect: '/index/detail'
    }, {
        path: '/registration',
        name: 'registration',
        component: () => import('@/views/RegistrationView.vue')
    }, {
        path: '/forgotPassword',
        component: () => import('@/views/ForgotPasswordView.vue')
    }, {
        path: '/index',
        component: () => import('@/views/IndexView.vue'),
        children: [{
            path: 'detail',
            component: () => import('@/views/IndexDetailView.vue'),
        }, {
            path: 'information',
            component: () => import('@/views/InformationView.vue')
        }, {
            path: 'notice',
            component: () => import('@/views/NoticeView.vue')
        }, {
            path: 'editPassword',
            component: () => import('@/views/EditPasswordView.vue')
        }, {
            path: 'cancel',
            component: () => import('@/views/CancelView.vue')
        }]
    }, {
        path: 'edit',
        component: () => import('@/views/ContentEdit.vue')
    }, {
        path: 'content_preview',
        component: () => import('@/views/ContentPreview.vue')
    }, {
        path: 'articleList',
        component: () => import('@/views/ArticleList.vue')
    }, {
        path: 'edit/information',
        component: () => import('@/views/EditInformationView.vue')
    }]
}]

const router = new VueRouter({
    mode: "history",
    linkActiveClass: "active",
    routes
})

export default router