import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        api_loading: false,
        approveStatus: 0,
        logo: '',
        email: '',
        noticeCount: 0,
        type: 0
    }
})