import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import moment from 'moment';
import isMessage from './utils/message.js';

let stg_language = localStorage.getItem('language') || '';
// 全局  overall
function custom_moment(v) {
  if(stg_language=='en'){
    return moment(v).format('DD/MM/YYYY, hh:mma');
  }else{
    return moment(v).format('YYYY/MM/DD HH:mm');
  }
}
// 时间转换
Vue.prototype.overall_time = custom_moment;

// Vue.prototype.message = isMessage;

Vue.config.productionTip = false;

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
