<template>
    <div v-loading="$store.state.aws_loading" element-loading-background="rgba(0, 0, 0, 0.6)">
        <Header />
        <div class="main"><router-view /></div>
    </div>
</template>

<script>
export default {
    components: {
        Header: () => import("./components/header"),
    },
    data() {
        return {

        }
    },
    created() {
    }
}
</script>

<style scoped>
.main {
    height: calc(100vh - 100px);
    overflow: auto;
    box-sizing: border-box;
    padding: 30px 150px;
    background: #F9F9F9;
}

.main::-webkit-scrollbar {
    width: 5px;
}

.main::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #F1C788;
}
</style>